<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <FormBuilder />
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import FormBuilder from "../../../components/form-builder/form-builder";
export default {
    page: {
        title: "Form Builder",
    },
    components: {
        Layout,
        PageHeader,
        FormBuilder,
    },
    data() {
        return {
            title: "Form Builder",
            items: [
                {
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Form Builder",
                    active: true,
                },
            ],
            showModal: false,
        };
    },
}
</script>

<style>

</style>